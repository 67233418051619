<template>
  <div class="mt-1 mx-3">
    <create-group>
      <!-- <template v-slot:navigation>
        <go-back/>
      </template> -->
      
    </create-group>
  </div>
</template>

<script>
import CreateGroup from '@/components/wallet/registry-groups/create.vue';
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CreateGroup,
  },
  created() {
    this.notifyQueue({ text: "Crea Gruppo", path: "registry_groups.create", level: 3 });
  }
}
</script>