<template>
  <div class="mt-1 mx-3">
    <registry-groups>
    </registry-groups>
  </div>
</template>

<script>
import RegistryGroups from '@/components/wallet/registry-groups/index.vue'
export default {
  props: {
  },
  components: {
    RegistryGroups
  },
  created() {
  }
}
</script>
